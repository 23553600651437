import { TooltipBody, TooltipContent } from 'components/Tooltip/StyledTooltip';
import TooltipLink from 'components/Tooltip/TooltipLink';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import Text from 'components/Typography/Text';
import { ReactNode } from 'react';

interface LinkProps {
    href: string;
    text: string;
}

interface Props {
    title?: string;
    body?: string | ReactNode;
    link?: LinkProps;
}

const RichTooltip = ({ title, body, link }: Props) => {
    return (
        <TooltipContent>
            {title && (
                <TooltipBody>
                    {title && (
                        <Heading size={HeadingSize.XSMALL}>{title}</Heading>
                    )}
                    <Text>{body}</Text>
                </TooltipBody>
            )}
            {link && <TooltipLink link={link} />}
        </TooltipContent>
    );
};
export default RichTooltip;
