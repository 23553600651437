import { useFlags } from 'external/launchDarkly';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { externalReferenceRegex } from '../../../../constants/regex';
import { useTeam } from '../../../../hooks/Queries';
import Input from '../../../FormElements/Input';
import ContentCard from '../../../Layout/ContentCard';
import Notification from '../../../Notifications/Notification';
import { CardContentWrapper } from '../../../ShipmentFormSlider/StyledShipmentFormSlider';
import Heading, { HeadingSize } from '../../../Typography/Heading';

const ReferenceSection = () => {
    const [senderReferenceInput, setSenderReferenceInput] = useState('');
    const team = useTeam();
    const { register, errors } = useFormContext();
    const { t } = useTranslation(['goods']);
    const { showSenderReceiverReference } = useFlags();

    return (
        <ContentCard withPadding withShadow>
            <CardContentWrapper>
                <Heading size={HeadingSize.MEDIUM}>
                    {t('goods:references')}
                </Heading>
                <Input
                    name="externalReference"
                    ref={register({
                        pattern: {
                            value: externalReferenceRegex,
                            message: t('errors.invoiceReference'),
                        },
                    })}
                    label={t('goods:invoiceReference')}
                    error={!!errors.externalReference}
                    errorMessage={errors.externalReference?.message}
                />
                {showSenderReceiverReference && (
                    <>
                        <Input
                            name="senderReference"
                            ref={register()}
                            maxLength={35}
                            label={t('goods:senderReference')}
                            onChange={(e) =>
                                setSenderReferenceInput(e.target.value)
                            }
                        />
                        {senderReferenceInput !== '' &&
                            team.team?.systemCountry === 'DE' && (
                                <Notification size="small" variant="note">
                                    {t('referencesWarning.DE')}
                                </Notification>
                            )}

                        <Input
                            name="receiverReference"
                            ref={register()}
                            maxLength={35}
                            label={t('goods:receiverReference')}
                        />
                    </>
                )}
            </CardContentWrapper>
        </ContentCard>
    );
};

export default ReferenceSection;
