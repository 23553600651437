import { Search } from 'assets/icons';
import { IconButton } from 'components/Buttons';
import Text from 'components/Typography/Text';
import styled, { css } from 'styled-components/macro';

interface InputProps {
    hasLeftDecorator: boolean;
    hasRightDecorator: boolean;
    hasError: boolean;
    readOnly: boolean;
}

interface WrapperProps {
    hasError: boolean;
    readOnly: boolean;
}

export const borderWidth = '1px';

export const InputContainer = styled.div`
    cursor: text;
    position: relative;
`;

export const MaxLengthText = styled(Text)<{ error: boolean }>`
    color: ${({ theme, error }) => (error ? theme.colors.red100 : null)};
`;

export const StyledInput = styled.input<InputProps>`
    /* Remove stepper, Chrome, Safari, Edge, Opera */

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Remove stepper, Firefox */
    -moz-appearance: textfield;
    background: transparent;
    border: none;
    box-sizing: border-box;
    color: ${({ theme, readOnly, hasError }) => {
        if (hasError) {
            return theme.colors.red100;
        }
        if (readOnly) {
            return theme.colors.grey100;
        }
        return theme.colors.black;
    }};
    flex: 1;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    min-width: 0px;
    outline: none;
    overflow: hidden;
    padding: ${({ theme }) => `${theme.space.s2} ${theme.space.s3}`};
    padding-left: ${({ hasLeftDecorator: hasPrefix }) =>
        hasPrefix ? '0px' : ''};
    padding-right: ${({ hasRightDecorator: hasSuffix }) =>
        hasSuffix ? '0px' : ''};
    position: relative;
    scroll-padding: ${({ theme }) => theme.space.s3};
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        height: 44px;
    }

    width: 100%;
`;
export const InputWrapper = styled.div<WrapperProps>`
    align-items: center;
    background-color: ${({ theme, readOnly }) =>
        readOnly ? theme.colors.grey500 : theme.colors.white};
    border: ${({ hasError, theme }) =>
        hasError
            ? `${borderWidth} solid ${theme.colors.red100}`
            : `${borderWidth} solid ${theme.colors.grey300}`};

    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    height: 44px;
    line-height: 20px;

    position: relative;
    width: 100%;

    &:focus-within {
        ${({ hasError, theme }) =>
            hasError
                ? css`
                      border: ${borderWidth} solid ${theme.colors.red100};
                      box-shadow: 0 0 0 1px inset ${theme.colors.red100};
                  `
                : css`
                      border: ${borderWidth} solid ${theme.colors.black};
                      box-shadow: 0 0 0 1px inset ${theme.colors.black};
                  `};
    }
`;

const TextDecorator = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    /* Clicks should fall down to the wrapper, to focus on the input. */
    pointer-events: none;
    white-space: nowrap;
`;

export const SearchIcon = styled(Search)`
    color: ${({ theme }) => theme.colors.grey100};
`;

export const DecoratorText = styled(Text)<{
    readOnly?: boolean;
}>`
    color: ${({ theme, readOnly }) => {
        if (readOnly) {
            return theme.colors.grey200;
        }
        return theme.colors.grey100;
    }};
`;

export const LeftTextDecorator = styled(TextDecorator)`
    margin-right: ${({ theme }) => theme.space.s2};
    padding-left: ${({ theme }) => theme.space.s3};
`;

export const RightTextDecorator = styled(TextDecorator)`
    margin-left: ${({ theme }) => theme.space.s1};
    padding-right: ${({ theme }) => theme.space.s3};
`;

export const ErrorMessage = styled.div`
    &::first-letter {
        text-transform: capitalize;
    }

    color: ${({ theme }) => theme.colors.red100};
    margin-top: ${({ theme }) => theme.space.s1};
`;

export const TopText = styled.div`
    align-items: center;
    cursor: default;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.space.s1};
    width: 100%;
`;

/** Custom styled IconButton, modified so it will fit inside the inset border of the input. */
export const PasswordButton = styled(IconButton)`
    height: 40px;
    margin-right: 1px;
    width: 40px;
`;

export const LabelWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: ${({ theme }) => theme.space.s2};
    justify-content: start;
`;
