/* eslint-disable promise/no-callback-in-promise */
import { searchAddressBook } from 'api/addressbook';
import { Contact, Person } from 'api/addressbook/types';
import { AsyncSelect } from 'components/FormElements/Select';
import { Alpha2Code } from 'i18n-iso-countries';
import debounce from 'lodash/debounce';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import AddressBookOption from './AddressBookOption';

type Props = {
    filters: {
        privateOnly?: boolean;
        businessOnly?: boolean;
        countryCode?: Alpha2Code;
        postalCode?: string;
        city?: string;
        state?: string;
    };
    onSelect: (option: Option | null) => void;
    value: Option | null;
};

export interface Option {
    value: {
        contact: Contact;
        person?: Person;
    };
    label: string;
}

/**
 * Lets the user search, asynchronously, for contacts in their address book.
 *
 * Displays one result per person in the people list of each contact.
 */
const AddressBookSearch = ({ filters, onSelect, value }: Props) => {
    const [error, setError] = useState(false);
    const { t } = useTranslation('address');
    // Fetch the address book contacts, with a slight debounce to allow for rapid typing.
    const loadOptions = debounce(
        (inputValue: string, callback: (options: Option[]) => void) => {
            setError(false);
            searchAddressBook(inputValue, filters)
                .then(({ data }) => {
                    const values = data
                        .map((contact) => {
                            // We want one option per contact person.
                            if (contact.people.length > 0) {
                                return contact.people.map((person) => ({
                                    value: { person, contact },
                                    label: contact.name,
                                }));
                            }
                            return [
                                {
                                    value: { contact },
                                    label: contact.name,
                                },
                            ];
                        })
                        .flat();
                    /*
                     react-select doesn't play nice with debounce, so we need to use callback rather than
                     returning a promise.
                     See https://github.com/JedWatson/react-select/issues/3075#issuecomment-450194917s
                    */
                    callback(values);
                    return undefined;
                })
                .catch(() => {
                    setError(true);
                    callback([]);
                });
        },
        250
    );
    return (
        <AsyncSelect<Option>
            cacheOptions
            defaultOptions
            components={{
                Option: AddressBookOption,
            }}
            loadOptions={loadOptions}
            onChange={onSelect}
            placeholder=""
            error={error}
            inputId="address-book-search"
            noOptionsMessage={() => t('addressBookSearch.noResults')}
            loadingMessage={() => t('addressBookSearch.loading')}
            errorMessage={t('addressBookSearch.error')}
            label={t('addressBookSearch.title')}
            value={value}
        />
    );
};

export default AddressBookSearch;
