// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components/macro';

import Link from '../Typography/Link';
import Text from '../Typography/Text';

export const StyledTooltipContainer = styled.div`
    div[role='tooltip'] {
        background: ${({ theme }) => theme.colors.black};
        border-radius: 0;
        max-width: 375px;
        white-space: pre-line; // Allows for new lines in the tooltip
        z-index: ${({ theme }) => theme.zIndex.tooltip} !important;
        padding: ${({ theme }) => `${theme.space.s1} ${theme.space.s2}`};
    }
`;
export const TooltipContent = styled.div`
    display: grid;
    grid-gap: ${({ theme }) => theme.space.s3};
    padding: ${({ theme }) => `${theme.space.s2} ${theme.space.s2}`};
`;

export const TooltipBody = styled(Text)`
    display: grid;
    grid-gap: ${({ theme }) => theme.space.s1};
    a {
        color: ${({ theme }) => theme.colors.white};
        font-weight: 500;
        text-decoration: underline;
    }
`;
export const StyledTooltipLink = styled(Link)`
    color: ${({ theme }) => theme.colors.white};
    font-weight: 500;
    text-decoration: underline;
`;
