import {
    BackendFavoritePackage,
    BackendPackage,
    BackendPackageRequest,
    FavoritePackage,
    Package,
    PackageRequest,
} from './types';

export const backendPackageRequestToPackageRequest = (
    pack: BackendPackageRequest
): PackageRequest => {
    return {
        weightKg: pack.weight_kg,
        depthCm: pack.depth_cm,
        widthCm: pack.width_cm,
        heightCm: pack.height_cm,
        stackable: pack.stackable,
        quantity: pack.quantity || 0,
        description: pack.description || '',
        name: pack.name || '',
        type: pack.type,
    };
};

export const packageToBackendPackage = (pack: Package): BackendPackage => {
    return {
        id: pack.id,
        name: pack.name,
        depth_cm: pack.depthCm,
        height_cm: pack.heightCm,
        weight_kg: pack.weightKg,
        width_cm: pack.widthCm,
        total_weight_kg: pack.totalWeightKg,
        tracking_ids: pack.trackingIds,
        quantity: pack.quantity,
        description: pack.description,
        stackable: pack.stackable,
        type: pack.type,
    };
};

export const backendPackageToPackage = (
    backendPackage: BackendPackage
): Package => {
    return {
        id: backendPackage.id,
        name: backendPackage.name,
        depthCm:
            typeof backendPackage.depth_cm === 'string'
                ? parseFloat(backendPackage.depth_cm)
                : backendPackage.depth_cm,
        heightCm:
            typeof backendPackage.height_cm === 'string'
                ? parseFloat(backendPackage.height_cm)
                : backendPackage.height_cm,
        weightKg:
            typeof backendPackage.weight_kg === 'string'
                ? parseFloat(backendPackage.weight_kg)
                : backendPackage.weight_kg,
        widthCm:
            typeof backendPackage.width_cm === 'string'
                ? parseFloat(backendPackage.width_cm)
                : backendPackage.width_cm,
        totalWeightKg: backendPackage.total_weight_kg,
        stackable: backendPackage.stackable,
        trackingIds: backendPackage.tracking_ids,
        quantity: backendPackage.quantity,
        description: backendPackage.description,
        type: backendPackage.type,
    };
};

export const toFavoritePackage = (
    f: BackendFavoritePackage
): FavoritePackage => {
    return { ...backendPackageToPackage(f), id: f.id };
};

export const toBackendPackageRequest = (
    pack: PackageRequest
): BackendPackageRequest => {
    return {
        name: pack.name,
        depth_cm: +pack.depthCm,
        height_cm: +pack.heightCm,
        weight_kg: +pack.weightKg,
        width_cm: +pack.widthCm,
        quantity: +pack.quantity,
        description: pack.description,
        stackable: pack.stackable,
        type: pack.type,
    };
};
