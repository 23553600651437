import { Language } from 'helpers/LanguageHelper';
import { DateTime } from 'luxon';

export type SignupMethod = 'search' | 'standard' | 'invite' | 'partnership';

export interface BackendUser {
    activated: number;
    created_at: string;
    email: string;
    first_name: string;
    id: number;
    last_name?: string;
    mobile_number: string;
    updated_at: string;
    username?: string;
    hubspot_token?: string;
    show_navigation: boolean;
}

export interface BackendUserUpdate {
    first_name: string;
    last_name?: string;
    mobile_number: string;
}

export interface SignupRequest {
    teamName: string;
    mobileNumber: string;
    email: string;
    password: string;
    systemCountry: string;
    userSettings: UserSettings;
    method: SignupMethod;
    code?: string;
}

export interface BackendSignupRequest {
    team_name: string;
    mobile_number: string;
    email: string;
    password: string;
    system_country: string;
    user_settings: BackendUserSetting;
    method: SignupMethod;
}

export interface User {
    activated: boolean;
    createdAt: DateTime;
    email: string;
    firstName: string;
    id: number;
    lastName?: string;
    mobileNumber: string;
    updatedAt: DateTime;
    username?: string;
    showNavigation: boolean;
}

export interface CurrentUser extends User {
    hubSpotToken: string;
}

export interface BackendUserPassword {
    new_password: string;
    new_password_confirmation: string;
    old_password: string;
}

export interface UserPassword {
    newPassword: string;
    newPasswordConfirmation: string;
    oldPassword: string;
}

export type BackendUserSetting =
    | { key: 'user_default_team_id'; value: string }
    | { key: 'user_lang'; value: Language | '' }
    | { key: 'user_new_feature_cost_control'; value: string }
    | { key: 'user_new_feature_invoice'; value: string }
    | { key: 'user_new_feature_settings'; value: string }
    | { key: 'user_non_stackable_for_half_pall'; value: string }
    | { key: 'user_non_stackable_for_pall'; value: string }
    | { key: 'user_non_stackable_for_standard'; value: string }
    | { key: 'user_qq_from_country_code'; value: string }
    | { key: 'user_qq_to_country_code'; value: string };

export interface UserSettings {
    userDefaultTeamId?: string;
    userLang?: Language;
    userNewFeatureCostControl?: string;
    userNewFeatureInvoice?: string;
    userNewFeatureSettings?: string;
    userNonStackableForHalfPall?: string;
    userNonStackableForPall?: string;
    userNonStackableForStandard?: string;
    userQqFromCountryCode?: string;
    userQqToCountryCode?: string;
}
export const ALL_STEPS = [
    'complete_registration',
    'book_shipment',
    'create_contact',
    'search_qq',
];

export type OnboardingStep = typeof ALL_STEPS[number];

export type OnboardingStatus = {
    [key in OnboardingStep]: {
        completed: boolean;
        dismissed: boolean;
    };
};
