import { ZIndex } from 'styled-components/macro';

const zIndex: ZIndex = {
    fixed: 970,
    sticky: 1005,
    dropdown: 1010,
    sliderBackdrop: 1030,
    slider: 1030,
    modalBackdrop: 1040,
    modal: 1050,
    popover: 1060,
    mobileMenu: 1070,
    tooltip: 1080,
    hubspot: 9999,
};
export default zIndex;
