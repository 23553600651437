import { DhlClaim } from 'components/ClaimForm/DHL/types';
import { DPDClaim } from 'components/ClaimForm/DPD/types';
import { DsvClaim } from 'components/ClaimForm/DSV/types';
import { SendifyInsuranceClaim } from 'components/ClaimForm/Sendify/types';
import { TntClaim } from 'components/ClaimForm/TNT/types';
import { UpsClaim } from 'components/ClaimForm/UPS/types';
import { Claim } from 'components/ClaimForm/types';

const claimToClaimRequest = async (claim: Claim): Promise<FormData> => {
    const formData = new FormData();

    formData.append('userEmail', claim.userEmail);
    formData.append('consignmentNumber', claim.consignmentNumber);
    formData.append('consignmentHash', claim.consignmentHash);
    formData.append('numberOfGoods', claim.numberOfGoods.toString());
    formData.append('weightOfGoods', claim.weightOfGoods.toString());
    formData.append('valueOfGoods', claim.valueOfGoods.toString());
    formData.append('bankAccount', claim.bankAccount);
    formData.append('currency', claim.currency);
    addFormField(formData, 'commercialInvoice', claim.commercialInvoice?.[0]);

    return formData;
};

export const upsClaimToUpsClaimRequest = async (
    claim: UpsClaim
): Promise<FormData> => {
    const formData = await claimToClaimRequest(claim);

    formData.append('goodsState', claim.goodsState.toString());
    formData.append('goodsDescription', claim.goodsDescription);
    formData.append('packaging', claim.packaging.toString());
    formData.append('packagingDescription', claim.packagingDescription);
    formData.append('seal', claim.seal.toString());
    formData.append('hasMissingArticle', claim.hasMissingArticle.toString());
    addFormField(
        formData,
        'availableForInspection',
        claim.availableForInspection.toString()
    );
    formData.append('claimIntent', claim.claimIntent);
    formData.append('currentLocation', claim.currentLocation);
    formData.append('discoveryDate', claim.discoveryDate.toISODate());
    addFormField(formData, 'goodsImage', claim.goodsImage?.[0]);
    addFormField(
        formData,
        'innerPackagingImage',
        claim.innerPackagingImage?.[0]
    );
    addFormField(
        formData,
        'outerPackagingImage',
        claim.outerPackagingImage?.[0]
    );
    addFormField(formData, 'otherDocument', claim.otherDocument?.[0]);
    addFormField(
        formData,
        'currentLocationClarification',
        claim.currentLocationClarification
    );

    return formData;
};

export const sendifyClaimToSendifyClaimRequest = async (
    claim: SendifyInsuranceClaim
): Promise<FormData> => {
    const formData = await claimToClaimRequest(claim);

    formData.append('goodsState', claim.goodsState.toString());
    formData.append('goodsDescription', claim.goodsDescription);
    formData.append('packaging', claim.packaging.toString());
    formData.append('packagingDescription', claim.packagingDescription);
    formData.append('seal', claim.seal.toString());
    formData.append(
        'hasMissingArticle',
        claim.hasMissingArticle?.toString() || ''
    );
    addFormField(
        formData,
        'availableForInspection',
        claim.availableForInspection?.toString() || ''
    );
    formData.append('damageImpact', claim?.damageImpact || '');
    formData.append('currentLocation', claim.currentLocation);
    formData.append('discoveryDate', claim.discoveryDate?.toISODate() || '');
    addFormField(formData, 'goodsImage', claim.files.goodsImage?.[0]);
    addFormField(
        formData,
        'innerPackagingImage',
        claim.files.innerPackagingImage?.[0]
    );
    addFormField(
        formData,
        'outerPackagingImage',
        claim.files.outerPackagingImage?.[0]
    );
    addFormField(formData, 'otherDocument', claim.files.otherDocument?.[0]);
    addFormField(
        formData,
        'commercialInvoice',
        claim.files.commercialInvoice?.[0]
    );
    addFormField(
        formData,
        'currentLocationClarification',
        claim.currentLocationClarification
    );

    return formData;
};
export const DPDClaimToDPDClaimRequest = async (
    claim: DPDClaim
): Promise<FormData> => {
    const formData = await claimToClaimRequest(claim);

    formData.append('goodsState', claim.goodsState.toString());
    formData.append('goodsDescription', claim.goodsDescription);
    formData.append('packaging', claim.packaging.toString());
    formData.append('packagingDescription', claim.packagingDescription);
    formData.append('seal', claim.seal.toString());
    formData.append(
        'hasMissingArticle',
        claim.hasMissingArticle?.toString() || ''
    );
    addFormField(
        formData,
        'availableForInspection',
        claim.availableForInspection?.toString() || ''
    );
    formData.append('damageImpact', claim?.damageImpact || '');
    formData.append('currentLocation', claim.currentLocation);
    formData.append('discoveryDate', claim.discoveryDate?.toISODate() || '');
    addFormField(formData, 'goodsImage', claim.files.goodsImage?.[0]);
    addFormField(
        formData,
        'innerPackagingImage',
        claim.files.innerPackagingImage?.[0]
    );
    addFormField(
        formData,
        'outerPackagingImage',
        claim.files.outerPackagingImage?.[0]
    );
    addFormField(formData, 'otherDocument', claim.files.otherDocument?.[0]);
    addFormField(
        formData,
        'commercialInvoice',
        claim.files.commercialInvoice?.[0]
    );
    addFormField(
        formData,
        'currentLocationClarification',
        claim.currentLocationClarification
    );

    return formData;
};

export const dsvClaimToDsvClaimRequest = async (
    claim: DsvClaim
): Promise<FormData> => {
    const formData = await claimToClaimRequest(claim);

    formData.append('claimReason', claim.claimReason);
    formData.append('goodsType', claim.goodsType);
    formData.append('damageDescription', claim.damageDescription);
    formData.append('packagingDescription', claim.packagingDescription);
    formData.append('discoveryDate', claim.discoveryDate.toISODate());
    formData.append('hasInsurance', claim.hasInsurance.toString());
    formData.append('hasDSVInsurance', claim.hasDSVInsurance.toString());
    formData.append('hasDSVProtect', claim.hasDSVProtect.toString());
    addFormField(formData, 'goodsImage', claim.goodsImage?.[0]);
    addFormField(
        formData,
        'innerPackagingImage',
        claim.innerPackagingImage?.[0]
    );
    addFormField(
        formData,
        'outerPackagingImage',
        claim.outerPackagingImage?.[0]
    );
    addFormField(formData, 'otherDocument', claim.otherDocument?.[0]);
    addFormField(formData, 'shippingLabel', claim.shippingLabel?.[0]);
    addFormField(
        formData,
        'separateInsuranceCompany',
        claim.separateInsuranceCompany
    );

    return formData;
};

export const tntClaimToTntClaimRequest = async (
    claim: TntClaim
): Promise<FormData> => {
    const formData = await claimToClaimRequest(claim);

    formData.append('goodsDescription', claim.goodsDescription);
    formData.append('packagingDescription', claim.packagingDescription);
    formData.append('visibleDamage', claim.visibleDamage.toString());
    formData.append('discoveryDate', claim.discoveryDate.toISO());
    formData.append('hasTntInsurance', claim.hasTntInsurance.toString());
    formData.append('currentLocation', claim.currentLocation);
    formData.append('damagedGoodsValue', claim.damagedGoodsValue.toString());
    formData.append('claimedValue', claim.claimedValue.toString());
    addFormField(formData, 'goodsImage', claim.goodsImage?.[0]);
    addFormField(
        formData,
        'innerPackagingImage',
        claim.innerPackagingImage?.[0]
    );
    addFormField(
        formData,
        'outerPackagingImage',
        claim.outerPackagingImage?.[0]
    );
    addFormField(formData, 'otherDocument', claim.otherDocument?.[0]);
    addFormField(formData, 'shippingLabel', claim.shippingLabel?.[0]);
    addFormField(formData, 'pickupManifest', claim.pickupManifest?.[0]);

    addFormField(formData, 'repairCost', claim.repairCost?.toString());
    addFormField(
        formData,
        'separateInsuranceCompany',
        claim.separateInsuranceCompany
    );

    return formData;
};

export const dhlClaimToDhlClaimRequest = async (
    claim: DhlClaim
): Promise<FormData> => {
    const formData = await claimToClaimRequest(claim);

    formData.append('goodsType', claim.goodsType);
    formData.append('goodsDescription', claim.goodsDescription);
    formData.append('outerPackaging', claim.outerPackaging);
    formData.append('innerPackaging', claim.innerPackaging);
    formData.append('damageDescription', claim.damageDescription);
    formData.append('damageImpact', claim.damageImpact);
    formData.append('damageCost', claim.damageCost.toString());
    formData.append(
        'visibleDamageOnDelivery',
        claim.visibleDamageOnDelivery.toString()
    );
    addFormField(formData, 'goodsImage', claim.goodsImage?.[0]);
    addFormField(
        formData,
        'innerPackagingImage',
        claim.innerPackagingImage?.[0]
    );
    addFormField(
        formData,
        'outerPackagingImage',
        claim.outerPackagingImage?.[0]
    );
    addFormField(formData, 'otherDocument', claim.otherDocument?.[0]);
    addFormField(formData, 'proofOfDelivery', claim.proofOfDelivery?.[0]);
    addFormField(
        formData,
        'outerPackagingDescription',
        claim.outerPackagingDescription
    );
    addFormField(
        formData,
        'innerPackagingDescription',
        claim.innerPackagingDescription
    );

    return formData;
};

//Adds a field, if it is defined. If it is undefined, nothing is added.
const addFormField = (
    form: FormData,
    field: string,
    value: File | string | undefined | null
) => {
    if (value) {
        form.append(field, value);
    }
};
