import { get, patch, post, remove } from 'api';
import ApiError from 'api/ApiError';
import { backendMetadataToMetaData } from 'api/mapper';
import { BackendMetaData } from 'api/types';
import { Alpha2Code } from 'i18n-iso-countries';
import queryString from 'query-string';

import * as config from '../../config';
import {
    toBackendContactRequest,
    toBackendContactUpdate,
    toContact,
} from './mapper';
import {
    BackendContact,
    Contact,
    ContactRequest,
    ContactUpdate,
} from './types';

export const searchAddressBook = async (
    searchString: string,
    filters?: {
        privateOnly?: boolean;
        businessOnly?: boolean;
        countryCode?: Alpha2Code;
        postalCode?: string;
        city?: string;
        state?: string;
    },
    perPage?: number,
    page?: number
) => {
    const params = {
        q: searchString,
        private_only: filters?.privateOnly,
        business_only: filters?.businessOnly,
        country_code: filters?.countryCode,
        zip_code: filters?.postalCode,
        city: filters?.city,
        state: filters?.state,
        per_page: perPage,
        page: page,
    };
    const response = await get<{
        data: BackendContact[];
        meta: BackendMetaData;
    }>(
        `${config.goDomain}v2/addressBookSearch?${queryString.stringify(
            params,
            {
                skipEmptyString: true,
            }
        )}`
    );
    if (!response.parsedBody?.data || !response.parsedBody?.meta) {
        throw new ApiError(response);
    }
    return {
        meta: backendMetadataToMetaData(response.parsedBody.meta),
        data: response.parsedBody.data.map(toContact),
    };
};

export const addContact = async (contact: ContactRequest): Promise<Contact> => {
    const request = toBackendContactRequest(contact);
    const response = await post<{ data: BackendContact }>(
        `${config.goDomain}v2/addressBook`,
        request
    );
    if (!response.parsedBody) {
        throw new ApiError(response);
    }
    return toContact(response.parsedBody.data);
};

export const updateContact = async (contact: ContactUpdate) => {
    const response = await patch(
        `${config.goDomain}v2/addressBook/${contact.id}`,
        toBackendContactUpdate(contact)
    );
    return toContact(response.parsedBody);
};

export const deleteContact = async (id: number) => {
    const response = await remove(`${config.goDomain}v2/addressBook/${id}`);
    return response.parsedBody;
};
