import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { cancelSubscription } from 'api/subscriptions';
import {
    ConfirmButtonWrapper,
    Confirmation,
    Content,
    Footer,
    Overview,
    OverviewList,
    OverviewListItem,
    OverviewListLogo,
} from 'components/APISubscriptionChange/StyledAPISubscriptionChange';
import { Button } from 'components/Buttons';
import { ModalHeader } from 'components/Modal';
import Caption, { CaptionSize } from 'components/Typography/Caption';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import { monthlyCosts, yearlyCosts } from 'constants/integrations';
import { monthlyIntegrationCosts } from 'containers/Settings/components/Api/ApiIntegrations/constants';
import { formatCost } from 'helpers/CurrencyHelper';
import { useTeam } from 'hooks/Queries';
import { useSubscriptions } from 'hooks/Queries/subscriptions';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import NextSteps from './NextSteps';

interface Props {
    onSuccessfulSubmit?: () => void;
}

/**
 * Lets the user cancel an existing bundle.
 */
const CancelBundle = ({ onSuccessfulSubmit }: Props) => {
    const queryClient = useQueryClient();
    const { subscriptions } = useSubscriptions();
    const { t, i18n } = useTranslation('settings');
    const { team } = useTeam();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const subscription = subscriptions?.apiSubscription;
    const currentSubscription = subscription?.current;
    if (!subscription || !team || !currentSubscription) {
        return null;
    }

    const { interval } = subscription;
    const { tier, integrations } = currentSubscription;

    const onSubmit = async () => {
        if (!tier || !interval) {
            return;
        }
        setIsSubmitting(true);
        try {
            await cancelSubscription(team.currency, tier, interval);
            if (onSuccessfulSubmit) {
                onSuccessfulSubmit();
            }
            queryClient.invalidateQueries(['subscriptions']);
            queryClient.invalidateQueries(['team']);
        } catch (err) {
            Sentry.captureException(err);
            toast.error(t('api.subscriptionChange.errors.bundleChange'), {
                autoClose: false,
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const { currency } = team;

    let monthlyCost = monthlyCosts[tier][currency];
    if (interval === 'year') {
        monthlyCost = yearlyCosts[tier][currency] / 12;
    }
    const decimals = currency === 'EUR' ? 1 : 0;

    if (!subscription.current?.end) {
        return null;
    }
    return (
        <>
            <ModalHeader
                title={t('api.subscriptionChange.bundle.title.cancel')}
            />

            <Content>
                <Overview>
                    <div>
                        <Caption size={CaptionSize.MEDIUM}>
                            {t('api.subscriptionChange.bundle.cancelledTier')}
                        </Caption>
                        <OverviewList>
                            <OverviewListItem>
                                <Heading size={HeadingSize.SMALL}>
                                    {t(`tier.${tier}`)}
                                </Heading>
                                <Heading size={HeadingSize.MEDIUM}>
                                    {t('api.subscriptionChange.costPerMonth', {
                                        cost: formatCost(
                                            monthlyCost,
                                            i18n.language,
                                            currency,
                                            decimals
                                        ),
                                    })}
                                </Heading>
                            </OverviewListItem>
                        </OverviewList>
                    </div>
                    {integrations && integrations.length > 0 && (
                        <div>
                            <Caption size={CaptionSize.MEDIUM}>
                                {t(
                                    'api.subscriptionChange.bundle.cancelledIntegrations',
                                    {
                                        count: integrations.reduce(
                                            (acc, i) => acc + i.quantity,
                                            0
                                        ),
                                    }
                                )}
                            </Caption>
                            <OverviewList>
                                {integrations.map(
                                    ({ integration, quantity }) => {
                                        return Array.from(
                                            Array(quantity).keys()
                                        ).map((i) => (
                                            <OverviewListItem
                                                key={`${integration}-${i}`}
                                            >
                                                <OverviewListLogo
                                                    integration={integration}
                                                />
                                                <Heading
                                                    size={HeadingSize.MEDIUM}
                                                >
                                                    {t(
                                                        'api.subscriptionChange.costPerMonth',
                                                        {
                                                            cost: formatCost(
                                                                monthlyIntegrationCosts[
                                                                    integration
                                                                ][currency],
                                                                i18n.language,
                                                                currency,
                                                                decimals
                                                            ),
                                                        }
                                                    )}
                                                </Heading>
                                            </OverviewListItem>
                                        ));
                                    }
                                )}
                            </OverviewList>
                        </div>
                    )}
                </Overview>

                <NextSteps
                    listItems={[
                        t(
                            'api.subscriptionChange.bundle.nextSteps.untilCancelled'
                        ),
                        t(
                            'api.subscriptionChange.bundle.nextSteps.cancelDate',
                            {
                                date: subscription.current.end.toLocaleString(
                                    DateTime.DATE_MED
                                ),
                            }
                        ),
                        t(
                            'api.subscriptionChange.bundle.nextSteps.reactivateUntil',
                            {
                                date: subscription.current.end.toLocaleString(
                                    DateTime.DATE_MED
                                ),
                            }
                        ),
                    ]}
                />
            </Content>
            <Footer>
                <Confirmation>
                    <ConfirmButtonWrapper>
                        <Button
                            variant="danger"
                            fullWidth
                            loading={isSubmitting}
                            onClick={onSubmit}
                        >
                            {t('api.subscriptionChange.bundle.button.cancel')}
                        </Button>
                    </ConfirmButtonWrapper>
                </Confirmation>
            </Footer>
        </>
    );
};

export default CancelBundle;
