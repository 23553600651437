import { CourierCode } from '../consignments/types';
import {
    BackendCarrierAccount,
    BackendContact,
    BackendContactRequest,
    BackendContactUpdate,
    BackendPerson,
    BackendPersonRequest,
    Contact,
    ContactRequest,
    ContactUpdate,
    Person,
    PersonRequest,
} from './types';

const toBackendPersonRequest = (
    person: PersonRequest
): BackendPersonRequest => ({
    email: person.email,
    first_name: person.firstName,
    last_name: person.lastName,
    mobile_number_1: person.mobileNumber,
});

const toBackendPersonUpdate = (
    person: Partial<Person>
): Partial<BackendPerson> => ({
    id: person.id,
    first_name: person.firstName,
    last_name: person.lastName,
    email: person.email,
    mobile_number_1: person.mobileNumber,
});

export const toBackendContactRequest = (
    contactRequest: ContactRequest
): BackendContactRequest => {
    const carrierAccounts: BackendCarrierAccount[] = [];

    if (contactRequest.dhlCarrierAccount) {
        carrierAccounts.push({
            carrier: CourierCode.DHL_FREIGHT_SWEDEN,
            account_identifier: contactRequest.dhlCarrierAccount,
        });
    }

    return {
        name: contactRequest.name,
        customer_reference: contactRequest.customerReference,
        address: {
            address_line_1: contactRequest.address.addressLine1,
            address_line_2: contactRequest.address.addressLine2,
            address_line_3: contactRequest.address.addressLine3,
            city: contactRequest.address.city,
            country_code: contactRequest.address.countryCode,
            state: contactRequest.address.state,
            zip_code: contactRequest.address.postalCode,
        },
        is_private: contactRequest.isPrivate,
        people: contactRequest.people.map(toBackendPersonRequest),
        carrier_accounts: carrierAccounts,
    };
};

export const toBackendContactUpdate = (
    contact: ContactUpdate
): BackendContactUpdate => {
    const carrierAccounts: BackendCarrierAccount[] = [];

    if (contact.dhlCarrierAccount) {
        carrierAccounts.push({
            carrier: CourierCode.DHL_FREIGHT_SWEDEN,
            account_identifier: contact.dhlCarrierAccount,
        });
    }

    return {
        id: contact.id,
        name: contact.name,
        customer_reference: contact.customerReference,
        address: {
            id: contact.address?.id,
            address_line_1: contact.address?.addressLine1,
            address_line_2: contact.address?.addressLine2,
            address_line_3: contact.address?.addressLine3,
            city: contact.address?.city,
            country_code: contact.address?.countryCode,
            state: contact.address?.state,
            zip_code: contact.address?.postalCode,
        },
        is_private: contact.isPrivate,
        people: contact.people?.map(toBackendPersonUpdate),
        carrier_accounts: carrierAccounts,
    };
};

const toPerson = (backendPerson: BackendPerson): Person => ({
    id: backendPerson.id,
    email: backendPerson.email,
    firstName: backendPerson.first_name,
    lastName: backendPerson.last_name || undefined,
    mobileNumber: backendPerson.mobile_number_1,
});

export const toContact = (backendContact: BackendContact): Contact => ({
    id: backendContact.id,
    name: backendContact.name,
    customerReference: backendContact.customer_reference || undefined,
    address: {
        id: backendContact.address.id,
        addressLine1: backendContact.address.address_line_1,
        addressLine2: backendContact.address.address_line_2 || undefined,
        addressLine3: backendContact.address.address_line_3 || undefined,
        city: backendContact.address.city,
        postalCode: backendContact.address.zip_code || undefined,
        countryCode: backendContact.address.country_code,
        state: backendContact.address.state || undefined,
    },
    dhlCarrierAccount:
        backendContact.carrier_accounts?.map(
            (account: BackendCarrierAccount) => {
                if (account.carrier == CourierCode.DHL_FREIGHT_SWEDEN) {
                    return account.account_identifier;
                }
            }
        )[0] || '', // Grab the first DHL account number, or an empty string
    isPrivate: backendContact.is_private,
    people: backendContact.people.map(toPerson),
});
