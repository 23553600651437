import { StyledTooltipLink } from 'components/Tooltip/StyledTooltip';

interface Props {
    link: {
        href: string;
        text: string;
    };
}
const TooltipLink = ({ link }: Props) => {
    return (
        <StyledTooltipLink href={link.href} target="_blank">
            {link.text}
        </StyledTooltipLink>
    );
};

export default TooltipLink;
