import { LinkClickTrackingPlugin } from '@snowplow/browser-plugin-link-click-tracking';
import {
    SelfDescribingJson,
    TrackerConfiguration,
    addGlobalContexts,
    newTracker,
    setUserId,
    trackPageView,
    trackStructEvent,
} from '@snowplow/browser-tracker';
import { getToken, parseToken } from 'api/auth';
import { signupVersion } from 'types/LocalStorage';

/**
 * Creates a tracker that points to our event pipeline. This tracker does not
 * require the user to be signed in.
 */
export const createTracker = async () => {
    // eslint-disable-next-line max-len
    /* See https://docs.snowplow.io/docs/collecting-data/collecting-from-own-applications/javascript-trackers/web-tracker/tracker-setup/initialization-options/
     * for a full list of configuration options.
     */
    const trackerConfig: TrackerConfiguration = {
        appId: 'sendify-react',
        discoverRootDomain: true,
        cookieSameSite: 'Lax', // Recommended
        contexts: {
            // We don't use the web page context
            webPage: false,
            // Not needed since we send and parse the session ID in each event
            session: false,
        },
        plugins: [LinkClickTrackingPlugin()],
        postPath: '',
        sessionCookieTimeout: 900, // 15 minutes
    };
    const allowStatisticsCookies =
        window.Cookiebot?.consent?.statistics ||
        process.env.NODE_ENV === 'development'; // Cookiebot is not present in local development
    if (!allowStatisticsCookies) {
        trackerConfig.anonymousTracking = true;
    }
    newTracker(
        'tracker',
        process.env.REACT_APP_EVENT_PIPELINE_URL as string, // Will always be present as this is set up in Pulumi
        trackerConfig
    );
    addGlobalContexts([getUserContext]);
};

/**
 * Attaches a global user context entity, which will be sent along with every
 * event if the user is signed in.
 */
const getUserContext = () => {
    // The user ID and team hash is stored in the auth token and we will always
    // have this token if the user is signed in.
    const token = getToken();
    if (!token) {
        return undefined;
    }
    try {
        const { sub, team_hash } = parseToken(token);
        return {
            /**
             * This is the schema we use for user/team contexts. This is currently a
             * placeholder since we don't use Igluu in our data transformation step, but
             * matches the context name we use in the backend and event processor
             * lambda.
             *
             * See https://docs.snowplow.io/docs/understanding-your-pipeline/schemas/ for
             *  more information about schemas.
             */
            schema: 'iglu:com.sendify/user_context/jsonschema/1-0-0',
            data: { user_id: sub, team_hash: team_hash },
        };
    } catch (e) {
        return undefined;
    }
};

/**
 * Removes the user id from the tracker.
 */
export const clearUser = () => {
    setUserId(null);
};

/**
 * Sets the Snowplow user ID as a backup to the global context, but since that
 * relies on cookies, it is not guaranteed to be present.
 */
export const identify = (userId: number) => {
    setUserId(`${userId}`);
};

/**
 * Tracks an event to our pipeline.
 * @param name The name of the event
 * @param properties The properties of the event. Must be serializable to JSON.
 */
export const track = (
    name: string,
    properties: Record<string, any>,
    context?: SelfDescribingJson<Record<string, unknown>>[]
) => {
    trackStructEvent({
        action: name,
        category: 'event',
        property: JSON.stringify(properties),
        context,
    });
};

/**
 * Tracks a page view to our pipeline.
 */
export const trackPage = () => {
    const context: SelfDescribingJson<Record<string, unknown>>[] = [];
    // Add signup experiment context if we are on the signup page
    if (window.location.href.includes('/sign-up')) {
        context.push(
            getExperimentContext({
                signup_form_variant_24_q4: localStorage.getItem(signupVersion),
            })
        );
    }
    trackPageView({
        context,
    });
};

export const getExperimentContext = (
    experiments: Record<string, any>
): SelfDescribingJson<Record<string, string>> => {
    return {
        schema: 'iglu:com.sendify/experiment_context/jsonschema/1-0-0',
        data: experiments,
    };
};

/**
 * A snowplow context to hold the same value as what is sent in Google tag manager
 * transaction_id so that events can be correlated.
 *
 * @param transactionId must be the same as in the correlated GTM event
 */
export const getTransactionIdContext = (
    transactionId: string
): SelfDescribingJson<Record<string, string>> => ({
    schema: 'iglu:com.sendify/gtm_transaction_id_context/jsonschema/1',
    data: { transactionId },
});
