import {
    backendPackageRequestToPackageRequest,
    backendPackageToPackage,
    packageToBackendPackage,
    toBackendPackageRequest,
} from 'api/packages/mapper';
import { Addon } from 'api/search';
import { PriceSubscriptionTier } from 'api/subscriptions/types';
import DateHelper from 'helpers/DateHelper';
import { DateTime, DateTimeOptions } from 'luxon';

import {
    Address,
    AlternativePrice,
    BackendAddon,
    BackendAddress,
    BackendCarrierAddons,
    BackendConsignment,
    BackendConsignmentPatch,
    BackendConsignmentRequest,
    BackendCourier,
    BackendExternalSource,
    BackendOrder,
    BackendOwner,
    BackendPerson,
    BackendProduct,
    BackendSearchResult,
    BackendTrackingEvent,
    CarrierAddons,
    Consignment,
    ConsignmentPatch,
    ConsignmentRequest,
    ConsignmentStatus,
    Courier,
    CourierCode,
    ExternalSource,
    Order,
    Owner,
    PartyRequest,
    Person,
    Product,
    ProductCode,
    SearchResult,
    TrackingEvent,
} from './types';

const dateTimeFromISOorFormat = (
    date: string,
    format: string,
    opts?: DateTimeOptions
): DateTime => {
    const iso: DateTime = DateTime.fromISO(date, opts);
    return iso.isValid ? iso : DateTime.fromFormat(date, format, opts);
};

const backendCourierToCourier = (courier: BackendCourier): Courier => ({
    code: courier.code as CourierCode,
});

const courierToBackendCourier = (courier: Courier): BackendCourier => ({
    code: courier.code as CourierCode,
});

const backendProductToProduct = (product: BackendProduct): Product => ({
    code: product.code as ProductCode,
    courier: backendCourierToCourier(product.courier),
});

const productToBackendProduct = (product: Product): BackendProduct => ({
    code: product.code,
    courier: courierToBackendCourier(product.courier),
});

export const personToBackendPerson = (person: Person): BackendPerson => {
    return {
        id: person.id,
        first_name: person.firstName,
        last_name: person.lastName || '',
        mobile_number_1: person.mobilePhoneNumber,
        phone_number_1: person.phoneNumber,
        email: person.email,
        created_at: person.createdAt?.toISO(),
        updated_at: person.updatedAt?.toISO(),
    };
};

const backendPersonToPerson = (
    person?: BackendPerson | null
): Person | undefined => {
    if (!person) {
        return undefined;
    }
    return {
        id: person.id,
        firstName: person.first_name,
        lastName: person.last_name || undefined,
        mobilePhoneNumber: person.mobile_number_1,
        phoneNumber: person.phone_number_1,
        email: person.email,
        createdAt: person.created_at
            ? DateTime.fromISO(person.created_at)
            : undefined,
        updatedAt: person.updated_at
            ? DateTime.fromISO(person.updated_at)
            : undefined,
    };
};

export const backendOwnerToOwner = (owner: BackendOwner): Owner => ({
    activated: !!owner.activated,
    email: owner.email,
    firstName: owner.first_name,
    id: owner.id,
    lastName: owner.last_name || undefined,
    mobileNumber: owner.mobile_number,
    hasBooked: !owner.first_booker,
    createdAt: owner.created_at
        ? DateTime.fromISO(owner.created_at)
        : undefined,
    updatedAt: owner.updated_at
        ? DateTime.fromISO(owner.updated_at)
        : undefined,
    username: owner.username,
});

const ownerToBackendOwner = (owner: Owner): BackendOwner => ({
    activated: owner.activated ? 1 : 0,
    email: owner.email,
    first_name: owner.firstName,
    id: owner.id,
    last_name: owner.lastName || null,
    mobile_number: owner.mobileNumber,
    username: owner.username,
    first_booker: !owner.hasBooked,
    created_at: owner.createdAt?.toISO(),
    updated_at: owner.updatedAt?.toISO(),
});

export const addressToBackendAddress = (address: Address): BackendAddress => ({
    address_line_1: address.addressLine1,
    address_line_2: address.addressLine2 || '',
    address_line_3: address.addressLine3 || '',
    zip_code: address.postalCode?.replace(/\s/g, ''),
    city: address.city,
    state: address.state || '',
    country_code: address.countryCode,
    created_at: address.createdAt?.toISO(),
    updated_at: address.updatedAt?.toISO(),
});

export const backendAddressToAddress = (address: BackendAddress): Address => ({
    addressLine1: address.address_line_1,
    addressLine2: address.address_line_2 || undefined,
    addressLine3: address.address_line_3 || undefined,
    postalCode: address.zip_code,
    city: address.city,
    state: address.state || undefined,
    countryCode: address.country_code,
    createdAt: address.created_at
        ? DateTime.fromISO(address.created_at)
        : undefined,
    updatedAt: address.updated_at
        ? DateTime.fromISO(address.updated_at)
        : undefined,
});

export const consignmentToBackendConsignment = (
    consignment: Consignment
): BackendConsignment => {
    return {
        missed_pickup: consignment.missedPickup?.toISO() || null,
        addons: toBackendAddons(consignment.carrierAddons),
        desirable_product_code: consignment.productCode || null,
        external_reference: consignment.externalReference || '',
        sender_reference: consignment.senderReference,
        receiver_reference: consignment.receiverReference,
        error_message: consignment.errorMessage,
        external_source: consignment.externalSource
            ? externalSourceToBackendExternalSource(consignment.externalSource)
            : undefined,
        from_address: addressToBackendAddress(consignment.sender.address),
        from_is_private: consignment.sender.isPrivate,
        from_name: consignment.sender.name,
        from_person: consignment.sender.person
            ? personToBackendPerson(consignment.sender.person)
            : null,
        from_via: !!consignment.viaSender,
        from_via_address: consignment.viaSender
            ? addressToBackendAddress(consignment.viaSender.address)
            : null,
        from_via_is_private:
            consignment.viaSender?.isPrivate === undefined
                ? null
                : consignment.viaSender.isPrivate,
        from_via_name: consignment.viaSender?.name || null,
        from_via_person:
            consignment.viaSender && consignment.viaSender.person
                ? personToBackendPerson(consignment.viaSender.person)
                : null,
        id: consignment.id,
        owner: consignment.owner
            ? ownerToBackendOwner(consignment.owner)
            : undefined,
        packages: consignment.packages.map(packageToBackendPackage),
        pickup_at_earliest: consignment.pickupAtEarliest.toISO(),
        pickup_at_latest: consignment.pickupAtLatest?.toISO() || null,
        status: consignment.status,
        team_id: consignment.teamId,
        to_address: addressToBackendAddress(consignment.recipient.address),
        to_is_private: consignment.recipient.isPrivate,
        to_name: consignment.recipient.name,
        to_person: consignment.recipient.person
            ? personToBackendPerson(consignment.recipient.person)
            : null,
        to_via: !!consignment.viaRecipient,
        to_via_address: consignment.viaRecipient
            ? addressToBackendAddress(consignment.viaRecipient.address)
            : null,
        to_via_is_private:
            consignment.viaRecipient?.isPrivate === undefined
                ? null
                : consignment.viaRecipient.isPrivate,
        to_via_name: consignment.viaRecipient?.name || null,
        to_via_person:
            consignment.viaRecipient && consignment.viaRecipient.person
                ? personToBackendPerson(consignment.viaRecipient.person)
                : null,
        total_weight_kg: consignment.totalWeightKg,
        created_at: consignment.createdAt?.toISO(),
        updated_at: consignment.updatedAt?.toISO(),
        order: consignment.order
            ? orderToBackendOrder(consignment.order)
            : undefined,
        tags: consignment.tags,
        insurance: consignment.insurance,
        has_customs_invoices: consignment.hasCustomsInvoices,
    };
};

export const backendTrackingEventToTrackingEvent = (
    backendTrackingEvent: BackendTrackingEvent
): TrackingEvent => ({
    code: backendTrackingEvent.code,
    description: backendTrackingEvent.description,
    locationName: backendTrackingEvent.location_name,
    reference: backendTrackingEvent.reference,
    trackingId: backendTrackingEvent.tracking_id,
    createdAt: DateTime.fromISO(backendTrackingEvent.created_at, {
        setZone: true,
    }),
});

export const trackingEventToBackendTrackingEvent = (
    trackingEvent: TrackingEvent
): BackendTrackingEvent => ({
    code: trackingEvent.code,
    description: trackingEvent.description,
    location_name: trackingEvent.locationName,
    reference: trackingEvent.reference,
    tracking_id: trackingEvent.trackingId,
    created_at: trackingEvent.createdAt.toISO(),
});

export const backendExternalSourceToExternalSource = (
    backendExternalSource: BackendExternalSource
): ExternalSource => ({
    source: backendExternalSource.external_source,
    system: backendExternalSource.system,
    id: backendExternalSource.external_id,
});

export const externalSourceToBackendExternalSource = (
    externalSource: ExternalSource
): BackendExternalSource => ({
    external_source: externalSource.source,
    system: externalSource.system,
    external_id: externalSource.id,
});

export const backendOrderToOrder = (backendOrder: BackendOrder): Order => ({
    id: backendOrder.id,
    status: backendOrder.status,
    expectedCost: parseFloat(backendOrder.expected_cost),
    cost: parseFloat(backendOrder.cost),
    trackingId: backendOrder.tracking_id,
    courierLoadingId: backendOrder.courier_loading_id,
    estimatedDeliveryEarliestAt: DateTime.fromISO(
        backendOrder.estimated_delivery_earliest_at,
        {
            setZone: true,
        }
    ),
    estimatedDeliveryLatestAt: DateTime.fromISO(
        backendOrder.estimated_delivery_latest_at,
        {
            setZone: true,
        }
    ),
    /*
        created_at and updated_at are sometimes ISO 8601 formatted and sometimes
        formatted as 'yyyy-MM-dd TT'. We try both of these formats when parsing
        out their DateTime objects.
    */
    createdAt: backendOrder.created_at
        ? dateTimeFromISOorFormat(backendOrder.created_at, 'yyyy-MM-dd TT')
        : undefined,
    updatedAt: backendOrder.updated_at
        ? dateTimeFromISOorFormat(backendOrder.updated_at, 'yyyy-MM-dd TT')
        : undefined,
    canceledAt: backendOrder.canceled_at
        ? dateTimeFromISOorFormat(backendOrder.canceled_at, 'yyyy-MM-dd TT')
        : undefined,
    product:
        backendOrder.product && backendProductToProduct(backendOrder.product),
    trackingEvents: backendOrder?.tracking_events?.map(
        backendTrackingEventToTrackingEvent
    ),
    loadingInstructions: backendOrder.loading_instructions,
    unloadingInstructions: backendOrder.unloading_instructions,
});

export const orderToBackendOrder = (order: Order): BackendOrder => {
    return {
        id: order.id,
        status: order.status,
        expected_cost: order.expectedCost.toString(),
        cost: order.cost.toString(),
        tracking_id: order.trackingId,
        courier_loading_id: order.courierLoadingId,
        estimated_delivery_earliest_at:
            order.estimatedDeliveryEarliestAt.toISO(),
        estimated_delivery_latest_at: order.estimatedDeliveryLatestAt.toISO(),
        created_at: order.createdAt ? order.createdAt.toISO() : undefined,
        updated_at: order.updatedAt ? order.updatedAt.toISO() : undefined,
        product: order.product && productToBackendProduct(order.product),
        tracking_events: order?.trackingEvents?.map(
            trackingEventToBackendTrackingEvent
        ),
        loading_instructions: order.loadingInstructions,
        unloading_instructions: order.unloadingInstructions,
    };
};

/**
 * Gets the key from a string enum. This is a workaround, since there
 * is no built-in reverse mapper for string enums (see
 * https://www.typescriptlang.org/docs/handbook/enums.html#reverse-mappings)
 *
 * @param stringEnum An enum with strings as values.
 * @param value The value whose key you are looking for within the enum.
 * @returns Either the enum key with the associated value, or undefined if
 * no such key exists.
 */
const enumKeyFromValue = <T extends { [index: string]: string }>(
    stringEnum: T,
    value: string
): keyof T | undefined => {
    const keys = Object.keys(stringEnum).filter((x) => stringEnum[x] === value);
    return keys.length > 0 ? keys[0] : undefined;
};

/**
 * This might seem redundant, but is necessary since there is no reverse
 * mapper for string enums (e.g. ConsignmentStatus).
 * @param status A consignment status value, for example a string received
 * from the backend.
 * @returns The status as a proper ConsignmentStatus enum value.
 * @throws TypeError if the supplied status is invalid or unknown.
 */
const statusValueToStatus = (status: string): ConsignmentStatus => {
    const key = enumKeyFromValue(ConsignmentStatus, status);
    if (!key) {
        throw new TypeError(
            `No valid consignment status found based on input string: ${status}`
        );
    }
    return ConsignmentStatus[key];
};

const toCarrierAddons = (
    addons?: BackendCarrierAddons
): CarrierAddons | undefined => {
    if (addons) {
        const result: CarrierAddons = {
            loadingTailgateLift: addons.loading_tailgate_lift,
            unloadingTailgateLift: addons.unloading_tailgate_lift,
            deliveryNotice: addons.delivery_notice,
        };

        if (addons.notification) {
            result.notification = {
                isPrivate: addons.notification.is_private,
                cost: addons.notification.cost,
                currency: addons.notification.currency,
            };
        }

        if (addons.definite_loading) {
            result.definiteLoading = {
                loadingDate: DateTime.fromISO(
                    addons.definite_loading.loading_date,
                    { setZone: true }
                ),
            };
        }
        if (addons.definite_unloading) {
            result.definiteUnloading = {
                unloadingDate: DateTime.fromISO(
                    addons.definite_unloading.unloading_date,
                    { setZone: true }
                ),
            };
        }
        if (addons.insurance) {
            result.insurance = {
                cost: addons.insurance.cost,
                currency: addons.insurance.currency,
                goodsValue: addons.insurance.goods_value,
            };
        }
        if (addons.insurance_service_fee) {
            result.insuranceServiceFee = {
                cost: addons.insurance_service_fee.cost,
                currency: addons.insurance_service_fee.currency,
            };
        }
        if (addons.guaranteed_delivery_time) {
            result.guaranteedDeliveryTime = {
                cost: addons.guaranteed_delivery_time.cost,
                currency: addons.guaranteed_delivery_time.currency,
                timeOfDay: addons.guaranteed_delivery_time.time_of_day,
            };
        }
        if (addons.green_shipping) {
            result.greenShipping = {
                cost: addons.green_shipping.cost,
                currency: addons.green_shipping.currency,
            };
        }
        if (addons.delivery_without_proof) {
            result.deliveryWithoutProof = {
                cost: addons.delivery_without_proof.cost,
                currency: addons.delivery_without_proof.currency,
            };
        }

        if (addons.receiver_pays) {
            result.receiverPays = {
                receiverPaysAccountNumber:
                    addons.receiver_pays.receiver_pays_account_number,
            };
        }

        if (addons.without_pickup) {
            result.withoutPickup = {
                cost: addons.without_pickup.cost,
                currency: addons.without_pickup.currency,
            };
        }

        if (addons.pickup_notification) {
            result.pickupNotification = {
                cost: addons.pickup_notification.cost,
                currency: addons.pickup_notification.currency,
            };
        }
        if (addons.dangerous_goods_limited_quantity) {
            result.dangerousGoodsLimitedQuantity = {
                cost: addons.dangerous_goods_limited_quantity.cost,
                currency: addons.dangerous_goods_limited_quantity.currency,
            };
        }

        return result;
    }
    return undefined;
};
const toBackendAddons = (
    addons?: CarrierAddons
): BackendCarrierAddons | undefined => {
    if (addons) {
        const result: BackendCarrierAddons = {
            loading_tailgate_lift: addons.loadingTailgateLift,
            unloading_tailgate_lift: addons.unloadingTailgateLift,
            delivery_notice: addons.deliveryNotice,
        };

        if (addons.insuranceServiceFee) {
            result.insurance_service_fee = {
                cost: addons.insuranceServiceFee.cost,
                currency: addons.insuranceServiceFee.currency,
            };
        }

        if (addons.notification) {
            result.notification = {
                is_private: addons.notification.isPrivate,
                cost: addons.notification.cost,
                currency: addons.notification.currency,
            };
        }

        if (addons.definiteLoading) {
            result.definite_loading = {
                loading_date: addons.definiteLoading.loadingDate.toISO(),
            };
        }
        if (addons.definiteUnloading) {
            result.definite_unloading = {
                unloading_date: addons.definiteUnloading.unloadingDate.toISO(),
            };
        }
        if (addons.insurance) {
            result.insurance = {
                cost: addons.insurance.cost,
                currency: addons.insurance.currency,
                goods_value: addons.insurance.goodsValue,
            };
        }
        if (addons.guaranteedDeliveryTime) {
            result.guaranteed_delivery_time = {
                cost: addons.guaranteedDeliveryTime.cost,
                currency: addons.guaranteedDeliveryTime.currency,
                time_of_day: addons.guaranteedDeliveryTime.timeOfDay,
            };
        }
        if (addons.deliveryWithoutProof) {
            result.delivery_without_proof = {
                cost: addons.deliveryWithoutProof.cost,
                currency: addons.deliveryWithoutProof.currency,
            };
        }
        if (addons.receiverPays) {
            result.receiver_pays = {
                receiver_pays_account_number:
                    addons.receiverPays.receiverPaysAccountNumber,
            };
        }

        if (addons.withoutPickup) {
            result.without_pickup = {
                cost: addons.withoutPickup.cost,
                currency: addons.withoutPickup.currency,
            };
        }

        if (addons.pickupNotification) {
            result.pickup_notification = {
                cost: addons.pickupNotification.cost,
                currency: addons.pickupNotification.currency,
            };
        }

        if (addons.greenShipping) {
            result.green_shipping = {
                cost: addons.greenShipping.cost,
                currency: addons.greenShipping.currency,
            };
        }

        return result;
    }
    return undefined;
};
export const backendConsignmentToConsignment = (
    consignment: BackendConsignment
): Consignment => {
    return {
        missedPickup: consignment.missed_pickup
            ? DateTime.fromISO(consignment.missed_pickup)
            : null,
        id: consignment.id,
        carrierAddons: toCarrierAddons(consignment.addons),
        productCode: consignment.desirable_product_code || undefined,
        externalReference: consignment.external_reference || undefined,
        senderReference: consignment.sender_reference,
        receiverReference: consignment.receiver_reference,
        errorMessage: consignment.error_message,
        externalSource: consignment.external_source
            ? backendExternalSourceToExternalSource(consignment.external_source)
            : undefined,
        sender: {
            address: backendAddressToAddress(consignment.from_address),
            person: backendPersonToPerson(consignment.from_person),
            name: consignment.from_name,
            isPrivate: consignment.from_is_private,
        },
        viaSender: consignment.from_via
            ? {
                  // If from_via is true all via address parameters will be defined.
                  address: backendAddressToAddress(
                      consignment.from_via_address as BackendAddress
                  ),
                  person: backendPersonToPerson(
                      consignment.from_via_person as BackendPerson
                  ),
                  name: consignment.from_via_name as string,
                  isPrivate: Boolean(consignment.from_via_is_private),
              }
            : undefined,
        recipient: {
            address: backendAddressToAddress(consignment.to_address),
            person: backendPersonToPerson(consignment.to_person),
            name: consignment.to_name,
            isPrivate: consignment.to_is_private,
        },
        viaRecipient: consignment.to_via
            ? {
                  address: backendAddressToAddress(
                      consignment.to_via_address as BackendAddress
                  ),
                  person: backendPersonToPerson(
                      consignment.to_via_person as BackendPerson
                  ),
                  name: consignment.to_via_name as string,
                  isPrivate: Boolean(consignment.to_via_is_private),
              }
            : undefined,

        owner: consignment.owner
            ? backendOwnerToOwner(consignment.owner)
            : undefined,
        packages: consignment.packages.map(backendPackageToPackage),
        pickupAtEarliest: DateHelper.parseDate(consignment.pickup_at_earliest),
        pickupAtLatest: consignment.pickup_at_latest
            ? DateHelper.parseDate(consignment.pickup_at_latest)
            : undefined,
        status: statusValueToStatus(consignment.status),
        teamId: consignment.team_id,
        totalWeightKg: consignment.total_weight_kg,
        createdAt: consignment.created_at
            ? DateTime.fromISO(consignment.created_at)
            : undefined,
        updatedAt: consignment.updated_at
            ? DateTime.fromISO(consignment.updated_at)
            : undefined,
        order: consignment.order
            ? backendOrderToOrder(consignment.order)
            : undefined,
        tags: consignment.tags,
        insurance: consignment.insurance,
        hasCustomsInvoices: consignment.has_customs_invoices,
    };
};

// Partial meaning that the consignment object is not complete, it could be missing the recipient field.
export const partialBackendConsignmentToConsignment = (
    consignment: BackendConsignment
): Consignment => ({
    missedPickup: consignment.missed_pickup
        ? DateTime.fromISO(consignment.missed_pickup)
        : null,
    id: consignment.id,
    carrierAddons: toCarrierAddons(consignment.addons),
    productCode: consignment.desirable_product_code || undefined,
    externalReference: consignment.external_reference || undefined,
    senderReference: consignment.sender_reference,
    receiverReference: consignment.receiver_reference,
    errorMessage: consignment.error_message,
    externalSource: consignment.external_source
        ? backendExternalSourceToExternalSource(consignment.external_source)
        : undefined,
    sender: {
        address: backendAddressToAddress(consignment.from_address),
        person: backendPersonToPerson(consignment.from_person),
        name: consignment.from_name,
        isPrivate: consignment.from_is_private,
    },
    viaSender: consignment.from_via
        ? {
              // If from_via is true all via address parameters will be defined.
              address: backendAddressToAddress(
                  consignment.from_via_address as BackendAddress
              ),
              person: backendPersonToPerson(
                  consignment.from_via_person as BackendPerson
              ),
              name: consignment.from_via_name as string,
              isPrivate: Boolean(consignment.from_via_is_private),
          }
        : undefined,
    recipient: consignment.to_address
        ? {
              address: backendAddressToAddress(consignment.to_address),
              person: backendPersonToPerson(consignment.to_person),
              name: consignment.to_name,
              isPrivate: consignment.to_is_private,
          }
        : {
              address: {
                  addressLine1: '',
                  postalCode: '',
                  city: '',
                  countryCode: 'SE',
              },
              person: {
                  firstName: '',
                  mobilePhoneNumber: '',
                  phoneNumber: '',
                  email: '',
              },
              name: '',
              isPrivate: false,
          },
    viaRecipient: consignment.to_via
        ? {
              address: backendAddressToAddress(
                  consignment.to_via_address as BackendAddress
              ),
              person: backendPersonToPerson(
                  consignment.to_via_person as BackendPerson
              ),
              name: consignment.to_via_name as string,
              isPrivate: Boolean(consignment.to_via_is_private),
          }
        : undefined,

    owner: consignment.owner
        ? backendOwnerToOwner(consignment.owner)
        : undefined,
    packages: consignment.packages.map(backendPackageToPackage),
    pickupAtEarliest: DateHelper.parseDate(consignment.pickup_at_earliest),
    pickupAtLatest: consignment.pickup_at_latest
        ? DateHelper.parseDate(consignment.pickup_at_latest)
        : undefined,
    status: statusValueToStatus(consignment.status),
    teamId: consignment.team_id,
    totalWeightKg: consignment.total_weight_kg,
    createdAt: consignment.created_at
        ? DateTime.fromISO(consignment.created_at)
        : undefined,
    updatedAt: consignment.updated_at
        ? DateTime.fromISO(consignment.updated_at)
        : undefined,
    order: consignment.order
        ? backendOrderToOrder(consignment.order)
        : undefined,
    tags: consignment.tags,
    insurance: consignment.insurance,
    hasCustomsInvoices: consignment.has_customs_invoices,
});

export const toBackendConsignmentRequest = (
    consignment: ConsignmentRequest
): BackendConsignmentRequest => ({
    external_reference: consignment.externalReference || '',
    sender_reference: consignment.senderReference,
    receiver_reference: consignment.receiverReference,
    from_address: addressToBackendAddress(consignment.sender.address),
    from_is_private: consignment.sender.isPrivate,
    from_name: consignment.sender.name,
    from_person: personToBackendPerson(consignment.sender.person),
    from_via: !!consignment.viaSender,
    from_via_address: consignment.viaSender
        ? addressToBackendAddress(consignment.viaSender.address)
        : null,
    from_via_is_private:
        consignment.viaSender?.isPrivate === undefined
            ? null
            : consignment.viaSender.isPrivate,
    from_via_name: consignment.viaSender?.name || null,
    from_via_person:
        consignment.viaSender && consignment.viaSender.person
            ? personToBackendPerson(consignment.viaSender.person)
            : null,
    packages: consignment.packages.map(toBackendPackageRequest),
    pickup_at_earliest: consignment.pickupAtEarliest.toISO(),
    pickup_at_latest: consignment.pickupAtLatest.toISO(),
    to_address: addressToBackendAddress(consignment.recipient.address),
    to_is_private: consignment.recipient.isPrivate,
    to_name: consignment.recipient.name,
    to_person: personToBackendPerson(consignment.recipient.person),
    to_via: !!consignment.viaRecipient,
    to_via_address: consignment.viaRecipient
        ? addressToBackendAddress(consignment.viaRecipient.address)
        : null,
    to_via_is_private:
        consignment.viaRecipient?.isPrivate === undefined
            ? null
            : consignment.viaRecipient.isPrivate,
    to_via_name: consignment.viaRecipient?.name || null,
    to_via_person:
        consignment.viaRecipient && consignment.viaRecipient.person
            ? personToBackendPerson(consignment.viaRecipient.person)
            : null,
});

const toBackendFromVia = (fromVia: PartyRequest | undefined | null) => {
    if (fromVia === null) {
        // Should clear the backend via address
        return {
            from_via: false,
            from_via_address: null,
            from_via_is_private: null,
            from_via_name: null,
            from_via_person: null,
        };
    }
    if (fromVia) {
        // Should update the backend via address
        return {
            from_via: true,
            from_via_address: addressToBackendAddress(fromVia.address),
            from_via_is_private: fromVia.isPrivate,
            from_via_name: fromVia.name,
            from_via_person: fromVia.person
                ? personToBackendPerson(fromVia.person)
                : undefined,
        };
    }

    // Should not update the backend via address
    return {
        from_via: undefined,
        from_via_address: undefined,
        from_via_is_private: undefined,
        from_via_name: undefined,
        from_via_person: undefined,
    };
};

const toBackendToVia = (toVia: PartyRequest | undefined | null) => {
    if (toVia === null) {
        // Should clear the backend via address
        return {
            to_via: false,
            to_via_address: null,
            to_via_is_private: null,
            to_via_name: null,
            to_via_person: null,
        };
    }
    if (toVia) {
        // Should update the backend via address
        return {
            to_via: true,
            to_via_address: addressToBackendAddress(toVia.address),
            to_via_is_private: toVia.isPrivate,
            to_via_name: toVia.name,
            to_via_person: toVia.person
                ? personToBackendPerson(toVia.person)
                : undefined,
        };
    }

    // Should not update the backend via address
    return {
        to_via: undefined,
        to_via_address: undefined,
        to_via_is_private: undefined,
        to_via_name: undefined,
        to_via_person: undefined,
    };
};

export const toBackendConsignmentPatch = (
    consignment: ConsignmentPatch
): BackendConsignmentPatch => ({
    external_reference: consignment.externalReference,
    sender_reference: consignment.senderReference,
    receiver_reference: consignment.receiverReference,
    from_address: consignment.sender
        ? addressToBackendAddress(consignment.sender.address)
        : undefined,
    from_is_private:
        consignment.sender?.isPrivate === undefined
            ? undefined
            : consignment.sender.isPrivate,
    from_name: consignment.sender?.name || undefined,
    from_person: consignment.sender
        ? personToBackendPerson(consignment.sender.person)
        : undefined,
    ...toBackendFromVia(consignment.viaSender),
    packages: consignment.packages
        ? consignment.packages.map(toBackendPackageRequest)
        : undefined,
    pickup_at_earliest: consignment.pickupAtEarliest
        ? consignment.pickupAtEarliest.toISO()
        : undefined,
    pickup_at_latest: consignment.pickupAtLatest
        ? consignment.pickupAtLatest.toISO()
        : undefined,
    to_address: consignment.recipient
        ? addressToBackendAddress(consignment.recipient.address)
        : undefined,
    to_is_private:
        consignment.recipient?.isPrivate === undefined
            ? undefined
            : consignment.recipient.isPrivate,
    to_name: consignment.recipient?.name || undefined,
    to_person: consignment.recipient
        ? personToBackendPerson(consignment.recipient.person)
        : undefined,
    ...toBackendToVia(consignment.viaRecipient),
});

export const toConsignmentRequest = (
    consignment: BackendConsignmentRequest
): ConsignmentRequest => ({
    externalReference: consignment.external_reference || undefined,
    sender: {
        address: backendAddressToAddress(consignment.from_address),
        person: backendPersonToPerson(consignment.from_person) as Person,
        name: consignment.from_name,
        isPrivate: !!consignment.from_is_private,
    },
    viaSender: consignment.from_via_address
        ? {
              // If from_via_address is true all via address parameters will be defined.
              address: backendAddressToAddress(consignment.from_via_address),
              person: backendPersonToPerson(
                  consignment.from_via_person as BackendPerson
              ) as Person,
              name: consignment.from_via_name as string,
              isPrivate: !!consignment.from_via_is_private,
          }
        : undefined,
    recipient: {
        address: backendAddressToAddress(consignment.to_address),
        person: backendPersonToPerson(consignment.to_person) as Person,
        name: consignment.to_name,
        isPrivate: !!consignment.to_is_private,
    },
    viaRecipient: consignment.to_via_address
        ? {
              address: backendAddressToAddress(consignment.to_via_address),
              person: backendPersonToPerson(
                  consignment.to_via_person as BackendPerson
              ) as Person,
              name: consignment.to_via_name as string,
              isPrivate: !!consignment.to_via_is_private,
          }
        : undefined,
    packages: consignment.packages.map(backendPackageRequestToPackageRequest),
    pickupAtEarliest: DateTime.fromISO(consignment.pickup_at_earliest, {
        setZone: true,
    }),
    pickupAtLatest: DateTime.fromISO(consignment.pickup_at_latest, {
        setZone: true,
    }),
});

export const backendSearchResultToSearchResult = (
    searchResult: BackendSearchResult
): SearchResult => {
    let alternativePrice: AlternativePrice | null = null;

    // The backend will always return a struct for alternativePrice, but its values will be
    // nullish if there is no alternative price.
    if (
        searchResult.alternativePrice.price &&
        searchResult.alternativePrice.priceTier
    ) {
        alternativePrice = {
            price: searchResult.alternativePrice.price,
            // The tier from the backend will either be empty string or a tier - the former case is checked above.
            priceTier: searchResult.alternativePrice
                .priceTier as PriceSubscriptionTier,
        };
    }

    return {
        customerCost: Number(searchResult.customer_cost),
        currency: searchResult.currency,
        searchInformation: searchResult.search_information,
        weightKg: searchResult.weight_kg,
        loadingEarliestAt: DateTime.fromISO(searchResult.loading_earliest_at, {
            setZone: true,
        }),
        loadingLatestAt: DateTime.fromISO(searchResult.loading_latest_at, {
            setZone: true,
        }),
        loadingCutoffAt: DateTime.fromISO(searchResult.loading_cutoff_at, {
            setZone: true,
        }),
        unloadingEarliestAt: DateTime.fromISO(
            searchResult.unloading_earliest_at,
            {
                setZone: true,
            }
        ),

        unloadingLatestAt: DateTime.fromISO(searchResult.unloading_latest_at, {
            setZone: true,
        }),
        leadTimeWorkingDaysMin: searchResult.lead_time_working_days_min,
        leadTimeWorkingDaysMax: searchResult.lead_time_working_days_max,
        product: backendProductToProduct(searchResult.product),
        id: searchResult.id,
        addons: searchResult.addons.map(backendAddonToAddon),
        availableAddonCodes: searchResult.availableAddonCodes,
        unsupportedAddonCodes: searchResult.unsupportedAddonCodes,
        unsupportedAddonInput: searchResult.unsupportedAddonInput,
        alternativePrice,
        bookingRequirements: searchResult.bookingRequirements
            ? {
                  packages: searchResult.bookingRequirements.packages.map(
                      backendPackageToPackage
                  ),
                  farthestBookingTimeAllowed: searchResult.bookingRequirements
                      .farthestBookingTimeAllowed
                      ? DateTime.fromISO(
                            searchResult.bookingRequirements
                                .farthestBookingTimeAllowed,
                            { setZone: true }
                        )
                      : undefined,
                  pickupDaysAheadMax:
                      searchResult.bookingRequirements.pickupDaysAheadMax,
                  bookingPossibleBetween:
                      searchResult?.bookingRequirements?.bookingPossibleBetween,
              }
            : null,
        pickupTimeWindow: searchResult.pickupTimeWindow,
    };
};

const backendAddonToAddon = (addon: BackendAddon): Addon => ({
    code: addon.code,
    price: Number(addon.price),
    currency: addon.currency,
    included: addon.included,
    payload: {
        declaredValue: Number(addon.payload.declaredValue),
        declaredCurrency: addon.payload.declaredCurrency,
        definiteLoadingTime: addon.payload.definiteLoadingTime,
        definiteUnloadingTime: addon.payload.definiteUnloadingTime,
        instructions: addon.payload.instructions,
        timeOfDay: addon.payload.timeOfDay,
        receiverPaysAccountNumber: addon.payload.receiverPaysAccountNumber,
    },
    relationship: addon.relationship,
});
