import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { changeSubscriptionTier } from 'api/subscriptions';
import { APISubscriptionTier } from 'api/subscriptions/types';
import {
    ConfirmButtonWrapper,
    Confirmation,
    Content,
    Footer,
} from 'components/APISubscriptionChange/StyledAPISubscriptionChange';
import { Button } from 'components/Buttons';
import { ModalHeader } from 'components/Modal';
import Text from 'components/Typography/Text';
import { monthlyCosts, yearlyCosts } from 'constants/integrations';
import { monthlyIntegrationCosts } from 'containers/Settings/components/Api/ApiIntegrations/constants';
import { useTeam } from 'hooks/Queries';
import { useSubscriptions } from 'hooks/Queries/subscriptions';
import { DateTime, Duration } from 'luxon';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import CostBreakdown, { CostItem } from './CostBreakdown/CostBreakdown';
import NextSteps from './NextSteps';
import TierChange from './TierChange';

interface Props {
    selectedTier: APISubscriptionTier;
    onSuccessfulSubmit?: () => void;
}

/**
 * Lets the user downgrade to another tier.
 */
const DowngradeBundle = ({ selectedTier, onSuccessfulSubmit }: Props) => {
    const { subscriptions } = useSubscriptions();
    const { t, i18n } = useTranslation('settings');
    const { team } = useTeam();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const queryClient = useQueryClient();
    const subscription = subscriptions?.apiSubscription;
    const current = subscriptions?.apiSubscription.current;
    const currentTier = current?.tier;
    const isTrial = !!current?.trialEnd;

    const onSubmit = async () => {
        setIsSubmitting(true);

        if (
            !team?.currency ||
            !subscription ||
            selectedTier === null ||
            !subscription.interval
        ) {
            return;
        }
        try {
            await changeSubscriptionTier(
                selectedTier,
                team.currency,
                subscription.interval
            );
            if (onSuccessfulSubmit) {
                onSuccessfulSubmit();
            }
            queryClient.invalidateQueries(['subscriptions']);
            queryClient.invalidateQueries(['team']);
        } catch (err) {
            Sentry.captureException(err);
            toast.error(t('api.subscriptionChange.errors.bundleChange'), {
                autoClose: false,
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const nextSteps = useMemo(() => {
        if (!current?.end || !subscription) {
            return [];
        }
        return [
            t('api.subscriptionChange.bundle.nextSteps.activeFrom', {
                from: current.end.toLocaleString(DateTime.DATE_MED),
            }),
            subscription.interval === 'year'
                ? t('api.subscriptionChange.bundle.nextSteps.yearlyPayment')
                : t('api.subscriptionChange.bundle.nextSteps.monthlyPayment'),
            t('api.subscriptionChange.bundle.nextSteps.payForNewPlanFrom', {
                from: current.end.toLocaleString(DateTime.DATE_MED),
            }),
        ];
    }, [current, t, subscription, i18n]);

    const breakdownItems: CostItem[] = useMemo(() => {
        if (!current?.end || !team || !subscription) {
            return [];
        }
        const { interval } = subscription;

        if (isTrial) {
            const costItems: CostItem[] = [
                {
                    name: t('api.subscriptionChange.costBreakdown.tier', {
                        tier: t(`tier.${selectedTier}`),
                    }),
                    duration: Duration.fromObject(
                        {
                            months: interval === 'year' ? 12 : 1,
                        },
                        { conversionAccuracy: 'longterm' }
                    ).shiftTo('seconds'),
                    monthlyCost:
                        interval === 'year'
                            ? yearlyCosts[selectedTier][team.currency] / 12
                            : monthlyCosts[selectedTier][team.currency],
                    originalMonthlyCost:
                        interval === 'year'
                            ? monthlyCosts[selectedTier][team.currency]
                            : undefined,
                },
            ];
            if (current.integrations) {
                current.integrations.forEach((integrationInfo) => {
                    for (let i = 0; i < integrationInfo.quantity; i++) {
                        costItems.push({
                            name:
                                integrationInfo.integration === 'Other'
                                    ? t('api.ownIntegration')
                                    : t(
                                          'api.subscriptionChange.costBreakdown.integration',
                                          {
                                              integration:
                                                  integrationInfo.integration,
                                          }
                                      ),
                            duration: Duration.fromObject(
                                {
                                    months: interval === 'year' ? 12 : 1,
                                },
                                { conversionAccuracy: 'longterm' }
                            ).shiftTo('seconds'),
                            monthlyCost:
                                monthlyIntegrationCosts[
                                    integrationInfo.integration
                                ][team.currency],
                            originalMonthlyCost: undefined,
                        });
                    }
                });
            }
            return costItems;
        }
        return [];
    }, [t, current, isTrial, team, subscription]);

    const paymentText = useMemo(() => {
        if (!current?.end || !subscription || !team) {
            return null;
        }

        const { interval } = subscription;

        if (interval === 'year') {
            return t('api.subscriptionChange.bundle.yearInfo', {
                startDate: current.end.toLocaleString(DateTime.DATE_MED),
                billingDate: current.end.toLocaleString({
                    month: 'long',
                    day: 'numeric',
                }),
            });
        }
        return t('api.subscriptionChange.bundle.monthInfo', {
            startDate: current.end.toLocaleString(DateTime.DATE_MED),
            billingDate: current.end.day,
        });
    }, [current, subscription, t, team, i18n, breakdownItems]);

    if (!team || !currentTier || !current?.end || !subscription) {
        return null;
    }

    return (
        <>
            <ModalHeader
                title={t('api.subscriptionChange.bundle.title.downgrade')}
            />
            <Content>
                <TierChange newTier={selectedTier} />
                <NextSteps listItems={nextSteps} />
            </Content>
            <Footer>
                <Confirmation>
                    <CostBreakdown
                        items={breakdownItems}
                        isTrial={isTrial}
                        sumStartText={t('api.subscriptionChange.bundle.start', {
                            date: current.trialEnd
                                ? current.trialEnd.toLocaleString(
                                      DateTime.DATE_MED
                                  )
                                : current.end.toLocaleString(DateTime.DATE_MED),
                        })}
                    />
                    <ConfirmButtonWrapper>
                        <Text>{paymentText}</Text>
                        <Button
                            fullWidth
                            loading={isSubmitting}
                            onClick={onSubmit}
                        >
                            {t(
                                'api.subscriptionChange.bundle.button.downgrade',
                                {
                                    tier: t(`tier.${selectedTier}`),
                                }
                            )}
                        </Button>
                    </ConfirmButtonWrapper>
                </Confirmation>
            </Footer>
        </>
    );
};

export default DowngradeBundle;
