/* eslint-disable react/jsx-props-no-spreading */
import { SVGProps } from 'react';
import { useTranslation } from 'react-i18next';

import lightTheme from '../../../theme/lightTheme';
import { ReactComponent as RawAccount } from './account_circle.svg';
import { ReactComponent as RawAdd } from './add.svg';
import { ReactComponent as RawAddCircle } from './add_circle.svg';
import { ReactComponent as RawArrowDown } from './arrow_downward.svg';
import { ReactComponent as RawArrowUp } from './arrow_upward.svg';
import { ReactComponent as RawAvTimer } from './av_timer.svg';
import { ReactComponent as RawBubble } from './bubble.svg';
import { ReactComponent as RawCalendar } from './calendar_today.svg';
import { ReactComponent as RawChevronDown } from './chevron_down.svg';
import { ReactComponent as RawChevronLeft } from './chevron_left.svg';
import { ReactComponent as RawChevronRight } from './chevron_right.svg';
import { ReactComponent as RawChevronUp } from './chevron_up.svg';
import { ReactComponent as RawClose } from './close_black.svg';
import { ReactComponent as RawCode } from './code.svg';
import { ReactComponent as RawDelete } from './delete.svg';
import { ReactComponent as RawDoDisturb } from './do_disturb.svg';
import { ReactComponent as RawDocument } from './document.svg';
import { ReactComponent as RawDownload } from './download.svg';
import { ReactComponent as RawCompleteDownload } from './download_done.svg';
import { ReactComponent as RawEdit } from './edit.svg';
import { ReactComponent as RawFactCheck } from './fact_check.svg';
import { ReactComponent as RawFileDownload } from './file_download.svg';
import { ReactComponent as RawHelp } from './help_outline.svg';
import { ReactComponent as RawHide } from './hide.svg';
import { ReactComponent as RawImageSearch } from './image_search.svg';
import { ReactComponent as RawInfo } from './info.svg';
import { ReactComponent as RawMenu } from './menu.svg';
import { ReactComponent as RawNewReleases } from './new_releases.svg';
import { ReactComponent as RawNotification } from './notification.svg';
import { ReactComponent as RawNotificationActive } from './notification_active.svg';
import { ReactComponent as RawPallet } from './pallet.svg';
import { ReactComponent as RawPhoneInTalk } from './phone_in_talk.svg';
import { ReactComponent as RawPlace } from './place.svg';
import { ReactComponent as RawPrint } from './print.svg';
import { ReactComponent as RawRestartAlt } from './restart_alt.svg';
import { ReactComponent as RawSchedule } from './schedule.svg';
import { ReactComponent as RawSearch } from './search.svg';
import { ReactComponent as RawSend } from './send.svg';
import { ReactComponent as RawSettings } from './settings.svg';
import { ReactComponent as RawShare } from './share.svg';
import { ReactComponent as RawShoppingCart } from './shopping_cart.svg';
import { ReactComponent as RawShow } from './show.svg';
import { ReactComponent as RawStar } from './star.svg';
import { ReactComponent as RawStarBorder } from './star_border.svg';
import { ReactComponent as RawThumbDown } from './thumb_down_off_alt.svg';
import { ReactComponent as RawThumbUp } from './thumb_up.svg';
import { ReactComponent as RawTune } from './tune.svg';
import { ReactComponent as RawWareHouse } from './warehouse.svg';
import { ReactComponent as RawWhereToVote } from './where_to_vote.svg';
import { ReactComponent as RawWrapText } from './wrap_text.svg';

export const AddCircle = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawAddCircle
            {...props}
            role="img"
            aria-label={t('assets:icons.material.addCircle')}
        />
    );
};

export const Warehouse = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawWareHouse
            {...props}
            role="img"
            aria-label={t('assets:icons.material.warehouse')}
        />
    );
};
export const WrapText = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawWrapText
            {...props}
            role="img"
            aria-label={t('assets:icons.material.wrapText')}
        />
    );
};

export const Pallet = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawPallet
            {...props}
            role="img"
            aria-label={t('assets:icons.material.pallet')}
        />
    );
};

export const AvTimer = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawAvTimer
            {...props}
            role="img"
            aria-label={t('assets:icons.material.avTimer')}
        />
    );
};

export const ArrowDown = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawArrowDown
            {...props}
            role="img"
            aria-label={t('assets:icons.arrowDown')}
        />
    );
};

export const ArrowUp = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawArrowUp
            {...props}
            role="img"
            aria-label={t('assets:icons.arrowUp')}
        />
    );
};
export const ChevronDown = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawChevronDown
            {...props}
            role="img"
            aria-label={t('assets:icons.arrowDown')}
        />
    );
};

export const ChevronUp = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawChevronUp
            {...props}
            role="img"
            aria-label={t('assets:icons.arrowUp')}
        />
    );
};
export const ChevronLeft = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawChevronLeft
            {...props}
            role="img"
            aria-label={t('assets:icons.arrowLeft')}
        />
    );
};

export const ChevronRight = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawChevronRight
            {...props}
            role="img"
            aria-label={t('assets:icons.arrowRight')}
        />
    );
};

export const Calendar = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawCalendar
            {...props}
            role="img"
            aria-label={t('assets:icons.calendar')}
        />
    );
};

export const ShoppingCart = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawShoppingCart
            {...props}
            role="img"
            aria-label={t('icons.material.shoppingCart')}
        />
    );
};
export const Schedule = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawSchedule
            {...props}
            role="img"
            aria-label={t('icons.material.schedule')}
        />
    );
};

export const InfoCircle = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawInfo
            {...props}
            role="img"
            aria-label={t('icons.material.info_circle')}
        />
    );
};

export const Close = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawClose
            {...props}
            role="img"
            aria-label={t('icons.material.close_black')}
        />
    );
};
export const WhereToVote = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawWhereToVote
            {...props}
            role="img"
            aria-label={t('icons.material.where_to_vote')}
        />
    );
};

export const Place = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawPlace
            {...props}
            role="img"
            aria-label={t('icons.material.place')}
        />
    );
};

export const Bubble = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawBubble
            {...props}
            role="img"
            aria-label={t('icons.material.bubble')}
        />
    );
};

export const Tune = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawTune {...props} role="img" aria-label={t('icons.material.tune')} />
    );
};

export const Delete = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawDelete
            {...props}
            role="img"
            aria-label={t('icons.material.delete')}
        />
    );
};

export const Code = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawCode {...props} role="img" aria-label={t('icons.material.code')} />
    );
};

export const NewReleases = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawNewReleases
            {...props}
            role="img"
            aria-label={t('assets:icons.material.newReleases')}
        />
    );
};

export const ThumbUp = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawThumbUp
            {...props}
            role="img"
            aria-label={t('icons.material.thumbUp')}
        />
    );
};

export const ThumbDown = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawThumbDown
            {...props}
            role="img"
            aria-label={t('icons.material.thumbDown')}
        />
    );
};

export const Send = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawSend {...props} role="img" aria-label={t('icons.material.send')} />
    );
};

export const Edit = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawEdit {...props} role="img" aria-label={t('icons.material.edit')} />
    );
};

export const FileDownload = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawFileDownload
            {...props}
            role="img"
            aria-label={t('icons.material.download')}
        />
    );
};

export const Help = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawHelp {...props} role="img" aria-label={t('icons.material.help')} />
    );
};

export const Account = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawAccount
            {...props}
            role="img"
            aria-label={t('icons.material.account')}
        />
    );
};

export const Menu = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawMenu {...props} role="img" aria-label={t('icons.material.menu')} />
    );
};

export const Settings = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawSettings
            {...props}
            role="img"
            aria-label={t('assets:icons.material.settings')}
        />
    );
};
export const Show = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawShow
            {...props}
            role="img"
            aria-label={t('assets:icons.material.show')}
        />
    );
};

export const Hide = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawHide
            {...props}
            role="img"
            aria-label={t('assets:icons.material.hide')}
        />
    );
};

export const Search = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawSearch
            {...props}
            role="img"
            aria-label={t('assets:icons.material.search')}
        />
    );
};

export const Notification = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawNotification
            {...props}
            role="img"
            aria-label={t('assets:icons.material.notification')}
        />
    );
};

export const NotificationActive = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawNotificationActive
            {...props}
            role="img"
            aria-label={t('assets:icons.material.notificationActive')}
        />
    );
};
export const ImageSearch = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawImageSearch
            {...props}
            role="img"
            aria-label={t('assets:icons.material.imageSearch')}
        />
    );
};

export const DownloadComplete = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawCompleteDownload
            {...props}
            role="img"
            aria-label={t('assets:icons.material.downloadComplete')}
        />
    );
};
export const Share = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawShare
            {...props}
            role="img"
            aria-label={t('assets:icons.material.share')}
        />
    );
};

export const DoDisturb = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    const { fill = 'black' } = props;

    return (
        <RawDoDisturb
            {...props}
            fill={fill}
            role="img"
            aria-label={t('assets:icons.material.doDisturb')}
        />
    );
};

export const Print = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawPrint
            {...props}
            role="img"
            aria-label={t('assets:icons.material.print')}
        />
    );
};

export const Document = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawDocument
            {...props}
            role="img"
            aria-label={t('assets:icons.material.document')}
        />
    );
};

export const FavoriteStar = (props: SVGProps<SVGSVGElement>) => {
    // Use vinyl green 100 as the fill from the theme
    const { fill = lightTheme.colors.vinylGreen100 } = props;
    const { t } = useTranslation('assets');
    return (
        <RawStar
            {...props}
            fill={fill}
            role="img"
            aria-label={t('assets:icons.material.star')}
        />
    );
};

export const RestartAlt = (props: SVGProps<SVGSVGElement>) => {
    const { fill = 'black' } = props;
    const { t } = useTranslation('assets');

    return (
        <RawRestartAlt
            {...props}
            fill={fill}
            role="img"
            aria-label={t('icons.material.restartAlt')}
        />
    );
};

export const Star = (props: SVGProps<SVGSVGElement>) => {
    const { fill = 'black' } = props;
    const { t } = useTranslation('assets');

    return (
        <RawStar
            {...props}
            fill={fill}
            role="img"
            aria-label={t('icons.material.star')}
        />
    );
};
export const StarBorder = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawStarBorder
            {...props}
            role="img"
            aria-label={t('assets:icons.material.starBorder')}
        />
    );
};

export const Download = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawDownload
            {...props}
            role="img"
            aria-label={t('assets:icons.material.download')}
        />
    );
};

export const PhoneInTalk = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawPhoneInTalk
            {...props}
            role="img"
            aria-label={t('assets:icons.material.phone_in_talk')}
        />
    );
};

export const Add = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawAdd
            {...props}
            role="img"
            aria-label={t('assets:icons.material.add')}
        />
    );
};

export const FactCheck = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawFactCheck
            {...props}
            role="img"
            aria-label={t('assets:icons.material.factCheck')}
        />
    );
};
