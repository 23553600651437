import { Consignment } from 'api/consignments/types';
import { Delete, Edit, Send } from 'assets/icons';
import { IconButton } from 'components/Buttons';
import Divider from 'components/Divider';
import Endpoint from 'components/ShipmentSlider/Endpoint';
import Text, { TextSize } from 'components/Typography/Text';
import { useCartContext } from 'hooks/Cart';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonWrapper, ItemWrapper } from './StyledCartItem';

interface Props {
    consignment: Consignment;
}

const CartItem = ({ consignment }: Props) => {
    const { t, i18n } = useTranslation(['goods']);
    const {
        customEditCallback,
        openDeleteModal,
        openDraftModal,
        openEditModal,
    } = useCartContext();

    const shipmentInfo = useMemo(() => {
        let info = '';
        const quantity = consignment.packages
            .map((p) => p.quantity || 0)
            .reduce(
                (accumulator, currentQuantity) => accumulator + currentQuantity,
                0
            );
        const weight = consignment.packages.reduce(
            (acc, p) => acc + p.weightKg * p.quantity,
            0
        );
        info += `${t('cart:unit', {
            count: quantity,
        })} `;

        info += `${consignment.packages
            .reduce(
                (acc, p) =>
                    acc +
                    ((p.depthCm * p.heightCm * p.widthCm) / 1000000) *
                        p.quantity,
                0
            )
            .toFixed(3)} \u33A5. `; // \u33A5 = m^3
        info += Intl.NumberFormat(i18n.language, {
            style: 'unit',
            unit: 'kilogram',
            maximumFractionDigits: 2,
        }).format(weight);
        return info;
    }, [consignment.packages]);

    const openEdit = () => {
        if (customEditCallback) {
            customEditCallback(consignment);
        } else {
            openEditModal(consignment);
        }
    };

    return (
        <ItemWrapper data-testid={`cart-item-${consignment.id}`}>
            <Text size={TextSize.MEDIUM}>{shipmentInfo}</Text>
            <Divider />
            <Endpoint consignment={consignment} withBorder={false} />
            <Divider />
            <ButtonWrapper>
                <IconButton
                    id={`cart:saveAsDraft-${consignment.id}`}
                    icon={<Send />}
                    onClick={() => openDraftModal(consignment)}
                >
                    {t('cart:saveAsDraft')}
                </IconButton>
                <IconButton
                    id={`cart:edit-${consignment.id}`}
                    icon={<Edit />}
                    onClick={openEdit}
                >
                    {t('cart:edit')}
                </IconButton>
                <IconButton
                    id={`cart:remove-${consignment.id}`}
                    icon={<Delete />}
                    variant="danger"
                    onClick={() => openDeleteModal(consignment)}
                >
                    {t('cart:remove')}
                </IconButton>
            </ButtonWrapper>
        </ItemWrapper>
    );
};

export default CartItem;
